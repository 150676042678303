/* App.css */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
}

.MuiTableCell-root {
  padding: 16px;
}

.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  table-layout: fixed;
}

@media screen and (max-width: 768px) {
  .table {
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .table {
    width: 90%;
  }
}

@media screen and (min-width: 1201px) {
  .table {
    width: 100%;
  }
}
